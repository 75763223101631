/* ImageLoader.css */

/* Container styling */
.image-loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #000; /* Black background */
    color: #ff69b4; /* Pink text */
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(255, 255, 0, 0.2); /* Yellow shadow */
}

/* Image styling */
.image {
    max-width: 90%;
    height: 600px; /* Set the desired height */
    object-fit: cover; /* Maintain aspect ratio and cover the area */
    border-radius: 15px;
    margin: 10px 0;
    box-shadow: 0 4px 8px rgba(255, 255, 0, 0.2); /* Yellow shadow */
    transition: transform 1s;
}

/* Button styling */
.button {
    background-color: #ff69b4; /* Pink background */
    color: #000; /* Black text */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #ff1493; /* Darker pink on hover */
}

/* spin-out animation */
@keyframes spinOut {
    0% {
        transform: rotate(0deg) scale(1);
    }
    100% {
        transform: rotate(1080deg) scale(0);
    }
}

/* spin-in animation */
@keyframes spinIn {
    0% {
        transform: rotate(-1080deg) scale(0);
    }
    100% {
        transform: rotate(0deg) scale(1);
    }
}

/* apply animations separately */
.spin-out {
    animation: spinOut 1.5s forwards;
}

.spin-in {
    animation: spinIn 1.5s forwards;
}
